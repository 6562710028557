<template>
  <div>
    <highcharts ref="barChart" :options="chartOptions"></highcharts>
  </div>
</template>
<script>
export default {
  name: "chart-jenis-dinding",
   props: {
    Triger: null
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: [
             "Total Keluarga",
              "Total Keluarga Memiliki Rumah",
              "Dinding Tembok",
              "Dinding Plesteran",
              "Dinding Kayu",
              "Dinding Anyaman Bambu",
              "Dinding Seng", 
              "Lainya",
              "Kualitas Baik",
              "Kualitas Buruk",
          ]
        },
        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal
            );
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          },
          stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: 'gray'
            }
          }
        },
        legend: {
          enabled: true,
          reversed: true
        },
        plotOptions: {
          series: {
            stacking: "normal"
          }
        },
        series: [
          {
            name: "Non-OAP",
            data:  [16, 70, 10, 16, 60, 10, 16, 60, 10, 16 ],
            color: "#808080"
          },
          {
            name: "OAP",
            data: [36, 120, 20, 36, 120, 20, 36, 120, 20, 36 ],
            color: "#07980C"
          }
        ]
      }
    };
  },
  mounted() {
    this.getChartData()
  },
  methods: {
    getChartData() {
      this.$axios.get('/v1/sensus/district/jenis-dinding-terluas/'+ this.Triger)
        .then(response => {
          let result = response.data 
          this.chartOptions = {
            chart: {
              type: "bar"
            },
            title: {
              text: ""
            },
            xAxis: {
              categories: result.xAxis.categories
            },
            tooltip: {
              formatter: function() {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal
                );
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: ""
              },
              stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: 'gray'
                }
              }
            },
            legend: {
              enabled: true,
              reversed: true
            },
            plotOptions: {
              series: {
                stacking: "normal"
              }
            },
            series: result.series
          }
        })
    }
  }
};
</script>