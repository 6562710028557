<template>
  <div>
    <highcharts ref="barChart" :options="chartOptions"></highcharts>
  </div>
</template>
<script>
export default {
  name: 'chart-tingkat-pendidikan',
   props: {
    Triger: null
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column"
        },

        title: {
          text: ""
        },

        xAxis: {
          categories: [
            "Tidak Sekolah ",
            "SD",
            "SMP",
            "SMA",
            "SMK",
            "D3",
            "S1",
            "S2/S3"
          ]
        },

        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: ""
          }
        },
        legend: {
          enabled: true,
          position: "top"
        },
        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal +
              " Jiwa"
            );
          }
        },

        plotOptions: {
          column: {
            stacking: "normal"
          }
        },

        series: [
          {
            name: "Laki Laki",
            data: [36, 35, 20, 50, 36, 35, 20, 50],
            stack: "male",

            color: "#1490F7"
          },
          {
            name: "OAP Laki-Laki",
            data: [26, 25, 26, 20, 26, 25, 25, 30],
            stack: "male",
            color: "#1460F7"
          },
          {
            name: "Perempuan",
            data: [76, 85, 101, 98, 36, 35, 20, 50],
            stack: "female",
            color: "#FF5306"
          },
          {
            name: "OAP Perempuan",
            data: [56, 65, 85, 58, 26, 15, 10, 40],
            stack: "female",
            color: "#FF7104"
          }
        ]
      }
    };
  },
  mounted() {
    this.getChartData()
  },
  methods: {
    getChartData() {
      this.$axios.get('/v1/sensus/district/tingkat-pendidikan-terakhir/'+ this.Triger)
        .then(response => {
          let result = response.data 
          this.chartOptions = {
            chart: {
              type: "bar"
            },
            title: {
              text: ""
            },
            xAxis: {
              categories: result.xAxis.categories
            },
            tooltip: {
              formatter: function() {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  ": " +
                  this.y +
                  "<br/>" +
                  "Total: " +
                  this.point.stackTotal
                );
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: ""
              },
              stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: 'gray'
                }
              }
            },
            legend: {
              enabled: true,
              reversed: true
            },
            plotOptions: {
              series: {
                stacking: "normal"
              }
            },
            series: result.series
          }
        })
    }
  }
};
// import VueApexCharts from "vue-apexcharts";
// export default {
//      components:{
//      VueApexCharts
//    },
//     name:'chart-kependudkan',
//     data() {
//         return {
//              themeColors: ['#07980C', '#28C76F'],
//       columnChart: {
//         series: [
//            {
//             name: 'Laki-Laki',
//             data: [36, 35, 20, 50, 36, 35, 20, 50],
//           } ,

//            {
//             name: 'OAP Perempuan',
//             data: [76, 85, 101, 98, 36, 35, 20, 50 ]
//           } ,
//             {
//             name: 'Laki-Laki',
//             data: [36, 35, 20, 50, 36, 35, 20, 50],
//           } ,

//            {
//             name: 'Perempuan',
//             data: [76, 85, 101, 98, 36, 35, 20, 50 ]
//           }
//         ],

//         chartOptions: {
//           colors:  ['#1490F7','#FF5306' ],
//               chart: {
//             stacked: true,
//           },
//           plotOptions: {
//             bar: {
//               horizontal: false,
//               endingShape: 'flat',

//             },
//           },
//           dataLabels: {
//             enabled: false
//           },
//           stroke: {
//             show: true,
//             width: 2,
//             colors: ['transparent']
//           },

//           xaxis: {
//             categories: ['Tidak Sekolah ',  'SD', 'SMP',  'SMA', 'SMK', 'D3', 'S1', ' S2/S3'    ],
//           },
//           yaxis: {
//               show:false,
//             title: {
//               text: '$ (thousands)'
//             }
//           },
//           fill: {
//             opacity: 1

//           },
//           legend: {
//                 show: true,
//                 position: 'top',
//             },
//           tooltip: {
//             y: {
//               formatter: function(val) {
//                 return   val + " jiwa"
//               }
//             }
//           }
//         }
//       }
//       }
//     },
//     mounted() {

//     },
//     methods: {

//     },
// }
//
</script> 