<template>
  <div>
    <!-- <vue-apex-charts type="bar" height="350" :options="columnChart.chartOptions" :series="columnChart.series"></vue-apex-charts> -->
    <highcharts ref="barChart" :options="chartOptions"></highcharts>
  </div>
</template>
<script>
// import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    //  VueApexCharts
  },

  name: "chart-kependudkan",
  props: {
    Triger: null,
  },
  data() {
    return {
      themeColors: ["#07980C", "#28C76F"],
      DataSeries: [],
      chartOptions: {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Total Jiwa",
            "Total Laki Laki",
            "Total Perempuan",
            "Total KK",
          ],
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal 
            );
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        legend: {
          enabled: true,
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
                enabled: true
            }
          },
        },
        series: [],
      },
    };
  },
  mounted() {
    this.getChartDataKeluarga();
  },
  methods: {
    getChartDataKeluarga() {
      this.$axios
        .get("/v1/sensus/district/jiwa-kk-pria-wanita/" + this.Triger)
        .then(async (response) => {
           let data = response.data
            await this.chartOptions.series.push(
            {
              name: "Non-OAP",
              data: [
                data.jiwa.semua - data.jiwa.oap,
                data.pria.semua - data.pria.oap,
                data.wanita.semua - data.wanita.oap,
                data.kartu_keluarga.semua - data.kartu_keluarga.oap,
              ],
              color: "#808080",
            },
            {
              name: "OAP",
              data: [
                data.jiwa.oap,
                data.pria.oap,
                data.wanita.oap,
                data.kartu_keluarga.oap,
              ],
              color: "#07980C",
            }
          );
        });
    },
     
  },
};
</script>