<template>
  <div>
    <div class="cotainer-standar text-center">
      <div class="dk-container">
        <span
          class="d-block font-30 font-res-16 mt-res-30 text-white font-weight-6 mb-40  pl-res-5 pr-res-5 "
        >
          DATA KEPENDUDUKAN {{ NameDesa }} <br />TAHUN 2023
        </span>
        <div class="container-standar mt-10 mb-10 pb-10">
          <div class="row">
            <div class="col-md-12 mb-10">
              <div class="card shadow">
                <div class="row">
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-data.svg"
                          alt
                        />
                      </div>
                      <h4
                        class="  font-20 text-center font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Jiwa
                        <br />
                        <strong>{{ total_jiwa | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-laki.svg"
                          alt
                        />
                      </div>
                      <h4
                        class=" text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Laki Laki
                        <br />
                        <strong>{{ total_pria | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-perempuan.svg"
                          alt
                        />
                      </div>
                      <h4
                        class="  text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Perempuan
                        <br />
                        <strong>{{ total_perempuan | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-jiwa.svg"
                          alt
                        />
                      </div>
                      <h4
                        class=" text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total KK
                        <br />
                        <strong>{{ total_kk | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 d-inline-flex">
              <div class="shadow card pad-12 w-100">
                <span
                  class="font-18 text-primary text-center pl-4 mb-10 font-weight-6"
                >
                  <strong>PENDUDUK SESUAI USIA </strong>
                </span>
                <!-- <span class="flex justify-center    w-100">
                  <selecYears class="width-50  w-res-80 "></selecYears>
                </span> -->
                <bar-detail :Triger="receverForRoot"></bar-detail>
              </div>
            </div>
            <div class="col-md-8 d-inline-flex">
              <div class="card w-full shadow">
                <span
                  class="font-18 text-primary text-center mt-10 pl-4 mb-10 font-weight-6"
                >
                  <strong>
                    PENDUDUK PAPUA BARAT
                    <br />
                    OAP/Non-OAP
                  </strong>
                </span>
                <!-- <span class="flex justify-center    w-100">
                  <selecYears class="width-30  w-res-80 "></selecYears>
                </span> -->
                <!-- <donutchart></donutchart> -->
                <grapichpenduduk
                  :Triger="receverForRoot"
                  class="w-full"
                ></grapichpenduduk>
              </div>
            </div>
          </div>
        </div>
        <div class="container-standar mt-0 mb-10">
          <div class="row">
            <div class="col-lg-12 inline-flex mt-6">
              <div class="card width-100 pt-10 pb-10 shadow">
                <span
                  class="font-18 font-res-16  text-primary text-center pl-4 mb-10 font-weight-6"
                >
                  <strong>TABEL PERSEBARAN OAP DISTRIK</strong>
                </span>
                <!-- <span class="flex justify-center mb-10   w-100">
                  <selecYears class="width-30  w-res-80 "></selecYears>
                </span> -->
                <div class="res-scroll">
                  <table class="table table-lg">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Kampung</th>
                        <th scope="col">Laki Laki</th>
                        <th scope="col">Perempuan</th>
                        <th scope="col">Sub Total</th>
                        <th scope="col">Presentase dari Total OAP Distrik</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="index" v-for="(data, index, fname) in dataOAP">
                        <th>{{ fname + 1 }}</th>
                        <td>{{ data.nama_kampung }}</td>
                        <td>{{ data.male_count }}</td>
                        <td>{{ data.female_count }}</td>
                        <td>{{ data.subtotal }}</td>
                        <td>{{ data.percentage }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <span class="pl-4 mb-10 font-weight-6">
                   <a class="d-block width-80 text-right margin-auto pt-2 font-weight-6" href="https://papua.bps.go.id/publication/2019/08/16/e43fc034219586add3878e48/provinsi-papua-dalam-angka-2019.html">Source: papua.bps.go.id</a>
                </span> -->
              </div>
            </div>
            <div class="col-lg-12 inline-flex mt-6">
              <div class="card width-100 shadow pad-20">
                <b-card no-body>
                  <b-tabs
                    pills
                    card
                    vertical
                    nav-wrapper-class="w-25 w-res-100"
                  >
                    <b-tab title="Data OAP dan Non OAP">
                      <b-card-text class="col-lg-12">
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong>Data OAP dan Non OAP</strong>
                            </h4>
                            <!-- <span class="flex justify-center mb-10   w-100">
                              <selecYears
                                class="width-30  w-res-80 "
                              ></selecYears>
                            </span> -->
                            <pie-chart :TrigerOAP="receverForRoot"></pie-chart>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Kepemilikan Adminduk"
                      @click="chekedfunction(2)"
                    >
                      <b-card-text class="col-lg-12">
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4 class="mb-20 mt-10 font-24 text-primary">
                              <strong>Data Kepemilikan Adminduk </strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30  w-res-100  "
                              ></selecYears> -->
                            </span>
                            <adminduk
                              v-if="tabactive_2"
                              :Triger="receverForRoot"
                            ></adminduk>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <!-- <b-tab
                      title="Pertumbuhan Penduduk"
                      @click="chekedfunction(3)"
                    >
                      <b-card-text class="col-lg-12">
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24 font-res-20 text-primary"
                            >
                              <strong>PERTUMBUHAN PENDUDUK </strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <selecYears
                                class="width-30   w-res-80"
                              ></selecYears>
                            </span>
                            <growth-chart
                              v-if="tabactive_3"
                              :Triger="receverForRoot"
                            ></growth-chart>
                          </div>
                        </div>
                        <a
                          class="d-block width-100 text-right margin-auto pt-2 pr-5 pb-5 font-weight-6"
                          href="https://papua.bps.go.id/publication/2019/08/16/e43fc034219586add3878e48/provinsi-papua-dalam-angka-2019.html"
                          >Source: papua.bps.go.id</a
                        >
                      </b-card-text>
                    </b-tab> -->
                    <b-tab
                      title="Data Kepala Keluarga"
                      @click="chekedfunction(4)"
                    >
                      <b-card-text>
                        <div class="width-95 mt-10">
                          <span
                            class="font-24 font-res-20 w-100 text-primary text-center pl-4 mb-10 d-inline-block"
                          >
                            <strong>DATA KEPALA KELUARGA </strong>
                          </span>
                          <span class="flex justify-center mb-10   w-100">
                            <!-- <selecYears
                              class="width-30  w-res-80 "
                            ></selecYears> -->
                          </span>
                          <grapichkk
                            v-if="tabactive_4"
                            :Triger="receverForRoot"
                          ></grapichkk>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Partisipasi Sekolah"
                      @click="chekedfunction(5)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24 font-res--0 text-primary"
                            >
                              <strong>Data Partisipasi Sekolah </strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30   w-res-80 "
                              ></selecYears> -->
                            </span>
                            <!-- <partisipasi></partisipasi> -->
                            <div class="res-scroll">
                              <table
                                class="table table-striped w-80"
                                v-if="tabactive_5"
                              >
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">
                                      Kelompok Berdasarkan Umur
                                    </th>
                                    <th scope="col">Belum Pernah Sekolah</th>
                                    <th scope="col">Masih Sekolah</th>
                                    <th scope="col">Tidak sekolah lagi</th>
                                    <th scope="col">Penyandang disabilitas</th>
                                    <th scope="col">
                                      Kepemilikan akte kelahiran
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template
                                    v-for="(value,
                                    name,
                                    index) in partisipasi_sekolah"
                                  >
                                    <tr v-bind:key="index">
                                      <th scope="row">{{ ++index }}</th>
                                      <td>
                                        <strong>{{ name }}</strong>
                                      </td>
                                      <td>{{ value.belum_sekolah }}</td>
                                      <td>{{ value.masih_sekolah }}</td>
                                      <td>{{ value.tidak_sekolah_lagi }}</td>
                                      <td>{{ value.disabilitas }}</td>
                                      <td>{{ value.punya_akta_lahir }}</td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Program Bantuan Sosial"
                      @click="chekedfunction(6)"
                    >
                      <b-card-text>
                        <div class="width-95 mt-10">
                          <span
                            class="font-24 w-100 text-primary text-center pl-4 mb-10 d-inline-block"
                          >
                            <strong>Program Bantuan Sosial </strong>
                          </span>
                          <span class="flex justify-center mb-10   w-100">
                            <!-- <selecYears
                              class="width-30   w-res-80"
                            ></selecYears> -->
                          </span>
                          <bansos
                            v-if="tabactive_6"
                            :Triger="receverForRoot"
                          ></bansos>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Kepemilikan Rumah" @click="chekedfunction(7)">
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24  font-res-20 text-primary"
                            >
                              <strong>Data Kepemilikan Rumah </strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30  w-res-80 "
                              ></selecYears> -->
                            </span>
                            <kepemilikanrumah
                              v-if="tabactive_7"
                              :Triger="receverForRoot"
                            ></kepemilikanrumah>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Status Tempat Tinggal"
                      @click="chekedfunction(8)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24  font-res-20 text-primary"
                            >
                              <strong>
                                Data Kepemilikan Tempat Tinggal vs Kepemilikan
                                Tanah
                                <br />Tempat Tinggal
                              </strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30 w-res-80  "
                              ></selecYears> -->
                            </span>
                            <statustempat
                              v-if="tabactive_8"
                              :Triger="receverForRoot"
                            ></statustempat>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Tingkat Pendidikan Terakhir"
                      @click="chekedfunction(9)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24  font-res-20 text-primary"
                            >
                              <strong>TINGKAT PENDIDIKAN TERAKHIR </strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30  w-res-80 "
                              ></selecYears> -->
                            </span>
                            <TingkatPendidikan
                              v-if="tabactive_9"
                              :Triger="receverForRoot"
                            ></TingkatPendidikan>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Jenis Lantai Terluas"
                      @click="chekedfunction(10)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24 font-res-20 text-primary"
                            >
                              <strong>Data Jenis Lantai Terluas </strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30 w-res-80  "
                              ></selecYears> -->
                            </span>
                            <JenisLantai
                              v-if="tabactive_10"
                              :Triger="receverForRoot"
                            ></JenisLantai>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Jenis Dinding Terluas"
                      @click="chekedfunction(11)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24 font-res-20 text-primary"
                            >
                              <strong>Data Jenis Dinding Terluas</strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30  w-res-80 "
                              ></selecYears> -->
                            </span>
                            <JenisDinding
                              v-if="tabactive_11"
                              :Triger="receverForRoot"
                            ></JenisDinding>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Sumber Air Minum" @click="chekedfunction(12)">
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24 font-res-20 text-primary"
                            >
                              <strong
                                >Data Sumber dan Cara Mendapatkan Air
                                Minum</strong
                              >
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30 w-res-80  "
                              ></selecYears> -->
                            </span>
                            <AirMinum
                              v-if="tabactive_12"
                              :Triger="receverForRoot"
                            ></AirMinum>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab
                      title="Sumber Penerangan"
                      @click="chekedfunction(13)"
                    >
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24  font-res-20 text-primary"
                            >
                              <strong>Data Sumber Penerangan </strong>
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30 w-res-80  "
                              ></selecYears> -->
                            </span>
                            <SumberPenerangan
                              v-if="tabactive_13"
                              :Triger="receverForRoot"
                            ></SumberPenerangan>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Bahan Bakar" @click="chekedfunction(14)">
                      <b-card-text>
                        <div class="row text-center">
                          <div class="width-95 d-inline-block">
                            <h4
                              class="mb-20 mt-10 font-24 font-res-20 text-primary"
                            >
                              <strong
                                >Data Bahan Bakar Utama untuk Memasak</strong
                              >
                            </h4>
                            <span class="flex justify-center mb-10   w-100">
                              <!-- <selecYears
                                class="width-30  w-res-80  "
                              ></selecYears> -->
                            </span>
                            <BahanBakar
                              v-if="tabactive_14"
                              :Triger="receverForRoot"
                            ></BahanBakar>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
            </div>
          </div>
        </div>

        <div
          class="container-standar table-overflow mt-4 card pt-5 pl-3 pl-3 pb-0"
        >
          <span
            class="d-block font-18 font-res-16 pl-res-5 pr-res-5 pt-res-5  text-primary font-weight-9 mb-10"
            >DATA KEPENDUDUKAN {{ NameDesa }}</span
          >
          <!-- <span class="flex justify-center mb-10   w-100">
            <selecYears class="width-30   w-res-80 "></selecYears>
          </span> -->
          <div class="col-md-12 text-right mb-20">
            <b-button @click="exportExcel" variant="success"
              ><b-icon icon="download" aria-hidden="true"></b-icon> Download
              Excel
            </b-button>
          </div>
          <div class="res-scroll">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nama Desa/ Kampung</th>
                  <!-- <th scope="col">Jumlah Desa/Kampung</th>  -->
                  <th scope="col">Total Jiwa</th>
                  <th scope="col">Total KK</th>
                  <th scope="col">Laki Laki</th>
                  <th scope="col">Perempuan</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(data, index) in dataKabDistric">
                  <tr v-bind:key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>
                      <a class="text-primary font-weight-6 cursor-pointer">{{
                        data.nama
                      }}</a>
                    </td>
                    <!-- <td>
                    <a class="text-primary font-weight-6  cursor-pointer" @click="showModalDetail(data.id)" >{{  data.jumlah_kampung }} Desa</a>
                  </td> -->
                    <td>{{ data.total_jiwa }} Jiwa</td>
                    <td>{{ data.total_keluarga }}</td>
                    <td>{{ data.laki_laki }}</td>
                    <td>{{ data.perempuan }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="modal_detail" size="xl" title="Data Desa/ Kampung">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama Distrik</th>
              <th scope="col">Jumlah Desa</th>
              <th scope="col">Total Jiwa</th>
              <th scope="col">Total KK</th>
              <th scope="col">Laki Laki</th>
              <th scope="col">Perempuan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(district, index) in data_district" v-bind:key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td class="cursor-pointer">
                <a href="/data-district" class="text-primary font-weight-6">{{
                  district.nama
                }}</a>
              </td>
              <td>
                <a href="/data-district" class="text-primary font-weight-6"
                  >{{ district.jumlah_kampung }} Desa</a
                >
              </td>
              <td>{{ district.total_jiwa }} Jiwa</td>
              <td>{{ district.total_keluarga }}</td>
              <td>{{ district.laki_laki }}</td>
              <td>{{ district.perempuan }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <span class="d-inline-block w-full float-right ">Source: papua.bps.go.id</span> -->
      </b-modal>
    </div>
  </div>
</template>
<script>
// import barchart from "./components/barchart";
// import donutchart from "./components/donutchart";
import PieChart from "./components_desa/pieChart";
import grapichkk from "./components_desa/grafik-kk";
// api belum
import grapichpenduduk from "./components_desa/grapichpenduduk";
// import GrowthChart from "./components_desa/growthChart";
// end

import BarDetail from "./components_desa/BarDetail";

import TingkatPendidikan from "./components_desa/TingkatPendidikan";
import adminduk from "./components_desa/adminduk";
// import MapsComponent from "./components_desa/mapsSVG";
import bansos from "./components_desa/bansos";
// import MapBox from "./components/mapbox";
import kepemilikanrumah from "./components_desa/kepemilikanrumah";
import statustempat from "./components_desa/statustempattinggal";
import JenisLantai from "./components_desa/jenislantai";
import JenisDinding from "./components_desa/jenisdinding";
import AirMinum from "./components_desa/airminum";
import SumberPenerangan from "./components_desa/sumberpenerangan";
import BahanBakar from "./components_desa/bahanbakar";
// import selecYears from "./components/select-years";
// import partisipasi from "./components/partisipasisekolah";
import XLSX from "xlsx";

export default {
  components: {
    // donutchart,
    grapichpenduduk,
    // selecYears,
    // GrowthChart,
    BarDetail,
    TingkatPendidikan,
    PieChart,
    // MapsComponent,
    grapichkk,
    adminduk,
    bansos,
    kepemilikanrumah,
    statustempat,
    // MapBox,
    JenisLantai,
    JenisDinding,
    AirMinum,
    SumberPenerangan,
    BahanBakar,
  },
  name: "data-kependudukan",
  data() {
    return {
      selected1: null,
      receverForRoot: this.$route.params.id,
      NameDesa: this.$store.state.districtstate,
      options1: [
        { value: null, text: "Tahun 2021" },
        { value: "a", text: "Tahun 2022" },
      ],
      dataOAP: [],
      total_pria: 0,
      total_perempuan: 0,
      total_kk: 0,
      total_jiwa: 0,
      partisipasi_sekolah: {},
      dataKabDistric: [],
      data_district: [],
      tabactive_1: true,
      tabactive_2: false,
      tabactive_3: false,
      tabactive_4: false,
      tabactive_5: false,
      tabactive_6: false,
      tabactive_7: false,
      tabactive_8: false,
      tabactive_9: false,
      tabactive_10: false,
      tabactive_11: false,
      tabactive_12: false,
      tabactive_13: false,
      tabactive_14: false,
      export: {
        dataKabDistric: [],
      },
    };
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    this.getTotalJenisPenduduk();
    this.getDataPartisipasiSekolah();
    this.persebaranOAP();
    this.getDatadistric();
    // this.getNameforDistric()
  },
  methods: {
    chekedfunction(newVal) {
      this.tabactive_1 = false;
      this.tabactive_2 = false;
      this.tabactive_3 = false;
      this.tabactive_4 = false;
      this.tabactive_5 = false;
      this.tabactive_6 = false;
      this.tabactive_7 = false;
      this.tabactive_8 = false;
      this.tabactive_9 = false;
      this.tabactive_10 = false;
      this.tabactive_11 = false;
      this.tabactive_12 = false;
      this.tabactive_13 = false;
      this.tabactive_14 = false;
      if (newVal == 1) {
        this.tabactive_1 = true;
      }
      if (newVal == 2) {
        this.tabactive_2 = true;
      }
      if (newVal == 3) {
        this.tabactive_3 = true;
      }
      if (newVal == 4) {
        this.tabactive_4 = true;
      }
      if (newVal == 5) {
        this.tabactive_5 = true;
      }
      if (newVal == 6) {
        this.tabactive_6 = true;
      }
      if (newVal == 7) {
        this.tabactive_7 = true;
      }
      if (newVal == 8) {
        this.tabactive_8 = true;
      }
      if (newVal == 9) {
        this.tabactive_9 = true;
      }
      if (newVal == 10) {
        this.tabactive_10 = true;
      }
      if (newVal == 11) {
        this.tabactive_11 = true;
      }
      if (newVal == 12) {
        this.tabactive_12 = true;
      }
      if (newVal == 13) {
        this.tabactive_13 = true;
      }
      if (newVal == 14) {
        this.tabactive_14 = true;
      }
    },
    getTotalJenisPenduduk() {
      this.$axios
        .get("/v1/sensus/district/jiwa-kk-pria-wanita/" + this.receverForRoot)
        .then((response) => {
          let result = response.data;
          this.total_pria = result.pria.semua;
          this.total_perempuan = result.wanita.semua;
          this.total_jiwa = result.jiwa.semua;
          this.total_kk = result.kartu_keluarga.semua;
        });
    },
    getDataPartisipasiSekolah() {
      this.$axios
        .get("/v1/sensus/district/partisipasi-sekolah/" + this.receverForRoot)
        .then((response) => {
          let result = response.data;
          this.partisipasi_sekolah = result;
        });
    },
    persebaranOAP() {
      this.$axios
        .get("v1/sensus/district/persebaran-oap/" + this.$route.params.id)
        .then((response) => {
          this.dataOAP = response.data;
        });
    },
    getDatadistric() {
      this.$axios
        .get("/v1/sensus/district/kependudukan/" + this.$route.params.id)
        .then((response) => {
          let result = response.data;
          this.dataKabDistric = result;
          let i = 0;
          result.forEach((e) => {
            this.export.dataKabDistric.push({
              No: i + 1,
              "Nama Desa/Kampung": e.nama,
              "Total Jiwa": e.total_jiwa,
              "Total KK": e.total_keluarga,
              "Laki-laki": e.laki_laki,
              Perempuan: e.perempuan,
            });
            i++;
          });
        });
    },

    showModalDetail(district_id) {
      this.$axios
        .get("/v1/sensus/district/kependudukan/" + district_id)
        .then((response) => {
          let result = response.data;
          this.data_district = result;
          this.$bvModal.show("modal_detail");
        });
    },
    exportExcel() {
      // On Click Excel download button

      // export json to Worksheet of Excel
      // only array possible

      // A workbook is the name given to an Excel file
      // make Workbook of Excel
      let objectMaxLength = [];
      for (let i = 0; i < this.export.dataKabDistric.length; i++) {
        let value = Object.values(this.export.dataKabDistric[i]);
        for (let j = 0; j < value.length; j++) {
          if (value[j] !== undefined) {
            if (typeof value[j] == "number") {
              objectMaxLength[j] = 15;
            } else {
              objectMaxLength[j] =
                objectMaxLength[j] >= value[j].length
                  ? (objectMaxLength[j] += 2)
                  : value[j].length;
            }
          } else {
            objectMaxLength[j] = 20;
          }
        }
      }

      var keluargaWscols = [
        { width: objectMaxLength[0] },
        { width: 30 },
        { width: 20 },
        { width: objectMaxLength[3] },
        { width: objectMaxLength[4] },
        { width: objectMaxLength[5] },
        { width: objectMaxLength[6] },
      ];

      var keluargaWS = XLSX.utils.json_to_sheet(this.export.dataKabDistric);
      var wb = XLSX.utils.book_new();

      // export Excel file
      setTimeout(() => {
        keluargaWS["!cols"] = keluargaWscols;

        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, keluargaWS, "Data Desa dan Kampung"); // sheetAName is name of Worksheet
        XLSX.writeFile(
          wb,
          "Data Kependudukan " + this.NameDesa + " - " + new Date() + ".xlsx"
        ); // name of the file is 'book.xlsx'
      }, 1000);
    },
  },
};
</script>
